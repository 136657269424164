(function($){
	"use strict";

	// Navbar Menu JS
	$('.navbar .navbar-nav li a').on('click', function(e){
		let anchor = $(this);
		$('html, body').stop().animate({
			scrollTop: $(anchor.attr('href')).offset().top - 100
		}, 1500);
		e.preventDefault();
	});
	$(document).on('click','.navbar-collapse.in',function(e) {
		if( $(e.target).is('a') && $(e.target).attr('class') != 'dropdown-toggle' ) {
			$(this).collapse('hide');
		}
	});
	$('.navbar .navbar-nav li a').on('click', function(){
		$('.navbar-collapse').collapse('hide');
		$('.burger-menu').removeClass('active');
	});

	// Header Sticky
	$(window).on('scroll',function() {
		if ($(this).scrollTop() > 120){
			$('.navbar').addClass("is-sticky");
		}
		else{
			$('.navbar').removeClass("is-sticky");
		}
	});

	// Burger Menu
	$('.others-option .burger-menu').on('click', function() {
		$(this).toggleClass('active');
		$('.navbar .navbar-nav').toggleClass('active');
	});
	$('.navbar-toggler .burger-menu').on('click', function() {
		$(this).toggleClass('active');
	});

	// Upcoming Matches Slides
	$('.upcoming-matches-slides').owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
        autoplayTimeout: 2500,
		autoplay: true,
		navRewind: false,
		margin: 30,
		navText: [
			"<i class='flaticon-right-arrow'></i>",
			"<i class='flaticon-right-arrow'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 2,
			}
		}
	});

	// Matches Highlights Slides
	$('.matches-highlights-slides').owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		navRewind: false,
		animateOut: 'fadeOut',
		items: 1,
		navText: [
			"<i class='flaticon-left'></i>",
			"<i class='flaticon-right'></i>"
		],
	});
	$(".matches-highlights-slides").on("translate.owl.carousel", function(){
		$(".single-matches-highlights-item .content h3").removeClass("animated fadeInUp").css("opacity", "0");
		$(".single-matches-highlights-item .content span").removeClass("animated fadeInUp").css("opacity", "0");
		$(".highlights-video .video-btn").removeClass("animated fadeInLeft").css("opacity", "0");
	});
	$(".matches-highlights-slides").on("translated.owl.carousel", function(){
		$(".single-matches-highlights-item .content h3").addClass("animated fadeInUp").css("opacity", "1");
		$(".single-matches-highlights-item .content span").addClass("animated fadeInUp").css("opacity", "1");
		$(".highlights-video .video-btn").addClass("animated fadeInLeft").css("opacity", "1");
	});

	// Count Time
	function makeTimer() {
		var endTime = new Date("September 20, 2022 17:00:00 PDT");
		var endTime = (Date.parse(endTime)) / 1000;
		var now = new Date();
		var now = (Date.parse(now) / 1000);
		var timeLeft = endTime - now;
		var days = Math.floor(timeLeft / 86400);
		var hours = Math.floor((timeLeft - (days * 86400)) / 3600);
		var minutes = Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60);
		var seconds = Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60)));
		if (hours < "10") { hours = "0" + hours; }
		if (minutes < "10") { minutes = "0" + minutes; }
		if (seconds < "10") { seconds = "0" + seconds; }
		$("#days").html(days + "<span>Days</span>");
		$("#hours").html(hours + "<span>Hours</span>");
		$("#minutes").html(minutes + "<span>Minutes</span>");
		$("#seconds").html(seconds + "<span>Seconds</span>");
	}
	setInterval(function() { makeTimer(); }, 0);

	// Popup Video
	$('.popup-youtube').magnificPopup({
		disableOn: 320,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.popup-hawk').magnificPopup({
        // items: {

        // },
        // src: '<div class="white-popup"><h1>LUKE C JACKSON (Author)</h1><br /><p>Luke is the author of four Young Adult novels, a picture storybook, films, and games. He has taught high school English Literature in Melbourne, Australia for more than 15 years, and has lectured at university in a range of subjects, including Visual Narrative. In 2017, he completed a Ph.D. focusing on the role of a writer in creating narrative-driven digital games. Whatever the medium, he is always seeking ways to push the boundaries of storytelling. </p></div>',
        type: 'inline',
		disableOn: 320,
		// type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.privacy-popup').magnificPopup({
        type: 'inline',
		disableOn: 320,
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.terms-popup').magnificPopup({
        type: 'inline',
		disableOn: 320,
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.copy-popup').magnificPopup({
        type: 'inline',
		disableOn: 320,
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.submissions-popup').magnificPopup({
        type: 'inline',
		disableOn: 320,
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});

    $('.art-popup').magnificPopup({
        type: 'inline',
		disableOn: 320,
		mainClass: 'mfp-fade',
		removalDelay: 160,
		preloader: false,
		fixedContentPos: false
	});


	// Gallery Slides
	$('.gallery-slides').owlCarousel({
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		navRewind: false,
		margin: 30,
		navText: [
			"<i class='flaticon-right-arrow'></i>",
			"<i class='flaticon-right-arrow'></i>"
		],
		responsive: {
			0: {
				items: 1,
			},
			576: {
				items: 2,
			},
			768: {
				items: 2,
			},
			1200: {
				items: 2,
			}
		}
	});

	// Popup Image
	$('.popup-btn').magnificPopup({
		type: 'image',
		gallery: {
			enabled: true,
		}
	});

	// WoW JS
	$(window).on ('load', function (){
        if ($(".wow").length) {
            let wow = new WOW ({
                boxClass:     'wow',      // Animated element css class (default is wow)
                animateClass: 'animated', // Animation css class (default is animated)
                offset:       20,         // Distance to the element when triggering the animation (default is 0)
                mobile:       true,       // Trigger animations on mobile devices (default is true)
                live:         true,       // Act on asynchronously loaded content (default is true)
            });
            wow.init();
        }
	});
}(jQuery));
